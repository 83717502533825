import { useAuth0 } from '@auth0/auth0-react';
import { useCallback, useMemo } from 'react';
import { getRedirectURL } from './getRedirectURL';
import { useApolloClient } from '@apollo/client';
import { useReactlytics } from '@one-snap/sparkles';
import * as Sentry from '@sentry/react';

export function useAuth() {
  const auth = useAuth0();
  const { tracking } = useReactlytics();
  const client = useApolloClient();
  const { logout: logoutWithAuth0 } = auth;
  const { getAccessTokenSilently } = useAuth0();

  const getAccessToken = useCallback(async () => {
    const authToken = await getAccessTokenSilently({
      authorizationParams: {
        audience: process.env.NEXT_PUBLIC_AUTH0_AUDIENCE
      }
    });
    return authToken;
  }, [getAccessTokenSilently]);

  const logout = useCallback(async () => {
    try {
      // attempt to reset the cache and store
      await client.clearStore();
      tracking.logSystemEvent('log', {
        event: 'apollo store cleared'
      });
    } catch (e) {
      console.log(e, 'error');
      Sentry.captureException(
        new Error(`error while clearing the apollo store: ${e}`)
      );
      tracking.logProcessEvent('error while clearing the apollo store', e);
    } finally {
      // ensure logout with Auth0 is called regardless of the outcome of cache reset
      tracking.logProcessEvent('log out user', {
        redirectUrl: getRedirectURL()
      });
      logoutWithAuth0({
        logoutParams: {
          returnTo: getRedirectURL()
        }
      });
    }
  }, [logoutWithAuth0, client]);

  return useMemo(
    () => (auth ? { ...auth, getAccessToken, logout } : defaultAuth),
    [auth, getAccessToken, logout]
  );
}

const defaultAuth = {
  isAuthenticated: false,
  isLoading: false,
  getAccessToken: () => '',
  loginWithRedirect: () => {},
  logout: () => {},
  user: {
    name: '',
    email: '',
    picture: ''
  }
};
