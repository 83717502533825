var _sentryCollisionFreeGlobalObject = typeof window != "undefined" ? window : typeof global != "undefined" ? global : typeof self != "undefined" ? self : {};
_sentryCollisionFreeGlobalObject["__sentryRewritesTunnelPath__"] = undefined;
_sentryCollisionFreeGlobalObject["SENTRY_RELEASE"] = {"id":"KJlRbj88N1kAsUQz4_7Y0"};
_sentryCollisionFreeGlobalObject["__sentryBasePath"] = undefined;
_sentryCollisionFreeGlobalObject["__rewriteFramesAssetPrefixPath__"] = "";

// This file configures the initialization of Sentry on the client.
// The config you add here will be used whenever a users loads a page in their browser.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from '@sentry/nextjs';

if (['uat', 'production', 'qa'].includes(process.env.NEXT_PUBLIC_ENVIRONMENT)) {
  Sentry.init({
    dsn: 'https://f617a02263a6a1e4e2542a97d8b65a8f@o1176419.ingest.sentry.io/4506706153635840',
    beforeSend(event) {
      Sentry.flush(2000); // Ensures events are sent before navigation
      return event;
    },
    // Adjust this value in production, or use tracesSampler for greater control
    tracesSampleRate: 0.5,

    // Setting this option to true will print useful information to the console while you're setting up Sentry.
    // debug: process.env.NEXT_PUBLIC_ENVIRONMENT === 'local',

    environment: process.env.NEXT_PUBLIC_ENVIRONMENT,

    integrations: []
  });
}
