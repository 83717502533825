import { useQuery } from '@apollo/client';
import { GET_USER_INFO } from '../queries/getCustomerInformation';
import { getUserInformation } from '../types/getUserInformation';

/**
 * Get customer information
 *
 * @returns
 */
export const useGetCustomerInformation = () => {
  return useQuery<getUserInformation>(GET_USER_INFO);
};
