import { useTranslations } from 'next-intl';
import { ChangeLanguage } from '../../ui/change-language';
import { useRouter } from 'next/router';
import Image from 'next/image';

export const Footer = ({ year }) => {
  const t = useTranslations();
  const { locale } = useRouter();
  const legalLinks = [
    {
      url: `${
        locale === 'en-US'
          ? 'https://www.snapfinance.com/privacy'
          : 'https://www.snapfinance.com/privacy-spanish'
      }`,
      language: 'shared.footer.privacy',
      testId: 'privacy-link'
    },
    {
      url: 'https://snapfinance.com/legal/terms-of-use',
      language: 'shared.footer.terms',
      testId: 'terms-link'
    },
    {
      url: 'https://snapfinance.com/legal/financing-options',
      language: 'shared.footer.financing',
      testId: 'financing-link'
    }
  ];
  const socialMediaLinks = [
    {
      url: 'https://www.facebook.com/approvalinasnap/?fref=ts',
      imgSrc: '/assets/footer/facebook.svg',
      alt: 'shared.footer.facebookAlt',
      testId: 'facebook-link'
    },
    {
      url: 'https://www.instagram.com/snapfinance/',
      imgSrc: '/assets/footer/instagram.svg',
      alt: 'shared.footer.instagramAlt',
      testId: 'instagram-link'
    },
    {
      url: 'https://www.twitter.com/snapfinance/',
      imgSrc: '/assets/footer/x.svg',
      alt: 'shared.footer.xAlt',
      testId: 'x-link'
    },
    {
      url: 'https://www.linkedin.com/company/snap-finance',
      imgSrc: '/assets/footer/linkedin.svg',
      alt: 'shared.footer.linkedinAlt',
      testId: 'linkedin-link'
    },
    {
      url: 'https://www.youtube.com/channel/UCpsGsvulbRGHK9eNdt2Lhxw',
      imgSrc: '/assets/footer/youtube.svg',
      alt: 'shared.footer.youtubeAlt',
      testId: 'youtube-link'
    }
  ];

  return (
    <footer
      id="footer"
      data-testid="footer"
      className="px-4 min-h-96 pb-12 mt-auto"
    >
      <div className="flex flex-col w-full justify-center lg:justify-between xl:max-w-7xl xl:mx-auto">
        <div className="w-full border-b py-3 mb-3">
          {/* <ChangeLanguage /> */}
        </div>
        <div className="flex flex-col lg:flex-row items-center justify-between pb-6">
          <div>
            {legalLinks.map((link) => (
              <a
                key={link.testId}
                data-testid={link.testId}
                target="_blank"
                className="text-SnapGrey400 hover:underline secondaryHeavyFont pr-5"
                rel="noreferrer"
                href={link.url}
              >
                {t(link.language)}
              </a>
            ))}
          </div>

          <div className="secondaryFont lg:pr-5 text-SnapGrey400 pb-3 lg:pb-0">
            <span data-testid="copyright">&copy; {year} Snap Finance</span>
          </div>
          <div className="">
            {socialMediaLinks.map((link) => (
              <a
                key={link.testId}
                data-testid={link.testId}
                target="_blank"
                className="px-2"
                rel="noreferrer"
                href={link.url}
              >
                <Image
                  src={link.imgSrc}
                  alt={t(`${link.alt}`)}
                  width="18"
                  height="18"
                  className="mb-1"
                />
              </a>
            ))}
          </div>
        </div>
      </div>
    </footer>
  );
};
