import { ApolloError } from '@apollo/client';
import * as Sentry from '@sentry/nextjs';
import { uuidWithPrefix } from 'utilities/constants';

interface CustomGraphQLError extends ApolloError {
  errorType?: string;
  errorInfo?: {
    errorCode?: string;
    sourceError: {
      error: [
        {
          type: string;
          code: string;
          message: string;
        }
      ];
      status: number;
    };
  };
  path?: readonly (string | number)[];
}

export const logFetchErrorWithSentry = (
  method: string,
  path: string,
  httpStatus: number,
  severity: Sentry.SeverityLevel,
  errorDetails: string,
) => {
  Sentry.withScope((scope) => {
    scope.setLevel(severity);
    const error = new Error(
      `${String(method)} ${String(path)} - ${String(httpStatus)} - ${errorDetails}`
    );
    const shortPath = path?.split('.com').pop();
    error.name = `${shortPath} - ${httpStatus} - ${errorDetails}`;
    Sentry.setTag('HTTP_URL', path);
    Sentry.setTag('HTTP_STATUS', httpStatus);
    Sentry.setTag('ProductType', 'LTO');

    const userContext = {
      ui: {
        pageUrl: window.location.href
      },
      detailedError: errorDetails || null,
      userId: uuidWithPrefix 
    };
    Sentry.setContext('userContext', userContext);
    Sentry.captureException(error);
  });
};

export const logGraphWithSentry = (error) => {
  const customError = error as unknown as CustomGraphQLError;
  const { message, path, errorType, errorInfo } = customError;
  const errorCode = errorInfo?.errorCode;
  const detailedError = JSON.stringify(customError);
  const errorMessage = errorInfo?.sourceError?.error?.[0].message;
  const errorDetailsParts = [
    message,
    errorType,
    errorCode,
    errorMessage,
    path?.[0]
  ].filter(Boolean); 

  const detailedGraphError = new Error(errorDetailsParts.join(' - '));
  detailedGraphError.name = `GraphQLError ${errorDetailsParts.join(' - ')}`;
 
  Sentry.setTag('operationName', path?.[0]);
  Sentry.setTag('status', errorCode);
  Sentry.setTag('refId', uuidWithPrefix)
  const userContext = {
    pageUrl: window.location.href,
    refId: uuidWithPrefix,
    detailedError: detailedError || null
  };
  Sentry.setContext('userContext', userContext);
  Sentry.captureException(detailedGraphError);
}
