import { gql } from '@apollo/client';
import { Address } from 'lib/models/Address';
import { Phone } from 'lib/models/Phone';

export const GET_USER_INFO = gql`
  query getCustomerInformation {
    getCustomerInformation {
      address {
        ${Address.Fields}
      }
      email
      id
      phoneNumbers {
        ${Phone.Fields}
      }
    }
  }
`;
