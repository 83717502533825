'use client';

import {
  createClient,
  KameleoonProvider,
  Environment
} from '@kameleoon/react-sdk';
import { isBrowser } from '@one-snap/toolbox';
import { useMemo } from 'react';

export function KameleoonProviderClient({ children }): JSX.Element {
  const client = useMemo(() => {
    const isLocal = isBrowser
      ? window.location.hostname === 'localhost'
      : false;

    return isBrowser
      ? createClient({
          siteCode: process.env.NEXT_PUBLIC_KAMELEOON_SITE_CODE,
          configuration: {
            requestTimeout: 2000,
            cookieDomain: isLocal ? 'localhost' : '.snapfinance.com',
            updateInterval:
              Number(process.env.NEXT_PUBLIC_KAMELEOON_UPDATE_INTERVAL) || 5,
            environment:
              process.env.NEXT_PUBLIC_ENVIRONMENT === 'local'
                ? ('development' as Environment)
                : (process.env.NEXT_PUBLIC_ENVIRONMENT as Environment)
          }
        })
      : null;
  }, []);
  return <KameleoonProvider client={client}>{children}</KameleoonProvider>;
}
