import { useTranslations } from 'next-intl';
import { PropsWithChildren } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import { sparklesMaterialTheme } from '@one-snap/sparkles';
import { AppMessage } from '@one-snap/next';
import { AuthenticatedGuard } from 'guards/authenticated-guard';
import { useAuth } from 'auth/useAuth';
import { SessionInactivity } from 'components/features/session-inactivity/session-inactivity';
import { Layout } from 'components/features/layout/layout';
import { NetworkErrorAlert } from 'components/ui/alerts/network-error';
import { FormFieldControlProvider } from '@one-snap/forms';
import { AppProps } from 'next/app';

export function OneSnapSparklesSetupProvider({
  Component,
  pageProps,
  router,
  hasNetworkError,
  setHasNetworkError
}: AppProps & {
  hasNetworkError: boolean;
  setHasNetworkError: (value: boolean) => void;
}) {
  const t = useTranslations();

  return (
    <FormFieldControlProvider
      getErrorMessage={(message, values) => t(message, values)}
    >
      <ThemeProvider theme={sparklesMaterialTheme}>
        <AppMessage>
          <AuthenticatedGuard>
            <LayoutResolver noLayout={pageProps?.noLayout}>
              <Component {...pageProps} />
            </LayoutResolver>
          </AuthenticatedGuard>
          <NetworkErrorAlert
            hasError={hasNetworkError}
            onDismissed={() => setHasNetworkError(false)}
          />
        </AppMessage>
      </ThemeProvider>
    </FormFieldControlProvider>
  );
}

function LayoutResolver({
  children,
  noLayout
}: PropsWithChildren & { noLayout: boolean }) {
  const { logout } = useAuth();

  const logoutUser = () => {
    logout();
  };

  return noLayout ? (
    <>
      {children}
      <SessionInactivity inactivityTimer={15} onTimeOut={logoutUser} />
    </>
  ) : (
    <Layout>
      {children}
      <SessionInactivity inactivityTimer={15} onTimeOut={logoutUser} />
    </Layout>
  );
}
