import { Response } from 'node-fetch'; // Import Response from node-fetch

export const getErrorDetails = async (err: Response | any) => {
  let errorDetails = 'An unknown error occurred'; // there is no response e.g. 403, CORS error
  if (err && err.constructor && err.constructor.name === 'Response') {
    try {
      const clonedResponse = err.clone();
      errorDetails = await clonedResponse.text();
    } catch (error) {
      console.error('Failed to read response body:', error);
    }
  }
  return errorDetails;
};
