import cn from 'classnames';
import Image from 'next/image';

export const Banner = () => {
  return (
    <div
      className={cn(
        'hidden lg:px-4 lg:flex justify-center bg-SnapBlue w-full py-5 bg-banner-left bg-no-repeat bg-[length:55px] bg-[position:0_-20px]'
      )}
    >
      <div className="flex flex-row lg:max-w-7xl justify-between w-full items-center">
        <div className="">
          <p className="text-5xl text-SnapWhite">
            Snap is even better in the app
          </p>
          <p className="text-lg text-SnapWhite">
            Manage your account, make payments, and more &ndash; right from your
            mobile device
          </p>
        </div>
        <div className="">
          <Image
            src="/assets/banner_qr_code.png"
            alt="qr-code"
            className="w-[105px]"
            width={379}
            height={279}
          />
        </div>
      </div>
    </div>
  );
};
