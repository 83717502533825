import { useRouter } from 'next/router';
import { useTranslations } from 'next-intl';
import { useFormatDate } from 'utilities/useFormatDate';
import { IconChevronLeft } from '@one-snap/sparkles';
import { useGetCustomerInformation } from 'lib/models/customerInformation/hooks/useGetCustomerInformation';
import { useAuth } from 'auth/useAuth';

export const Greeting = () => {
  const { data } = useGetCustomerInformation();
  const auth = useAuth()
  const customer = data?.getCustomerInformation;
  const t = useTranslations();
  const { formatDate } = useFormatDate();
  const currentDate = new Date();
  const formattedDate = formatDate(currentDate.toDateString());

  const loginPaths = ['/login', '/#/login'];
  const router = useRouter();
  const renderLayout = !loginPaths.includes(router.asPath);
  const isDashboardPage = router.asPath === '/dashboard';

  const onBack = () => {
    router.back();
  };

  return (
    renderLayout && (
      <div className="items-center bg-SnapSand py-4 lg:py-8 lg:px-4">
        <div className="flex flex-col md:flex-row mx-auto md:justify-between lg:max-w-7xl lg:items-center text-SnapDarkBlue px-4 lg:px-0">
          <h1
            className="text-xl md:text-3xl  font-bold lg:font-normal"
            data-testid="greeting"
          >
            {!isDashboardPage && (
              <button
                className="md:hidden pr-3 text-SnapGrey500"
                onClick={onBack}
                data-testid="back-btn"
              >
                <IconChevronLeft size="xs" />
              </button>
            )}
            {t('shared.greeting')} {auth?.user?.name}
          </h1>
          <h2 className="hidden lg:block capitalize" data-testid="current-date">
            {formattedDate}
          </h2>
        </div>
      </div>
    )
  );
};
